// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bioBanner.webp)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  secondCta: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bioTestPropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
}));

const PLFA = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Microbial Testing',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/microorganisms`,
    page_path: '/microorganisms',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  const handleBuy = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 189.95,
      items: [{
        id: 'RX002',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/237-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="microorganisms" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Know the Ecosystem in Your Soil
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Healthy soil supports a host of microrganisms that assist in breaking down
              nutrients for plants growing in your garden or lawn. By testing your soil
              for microbial life, you can gain insight into the health of your microorganism
              communities, and track progress towards making your growing spaces thrive.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Various micro organisms"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/microbiome.webp`}
            title="Microbiome"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            A Deeper Level of Knowledge
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            For those already monitoring their soil, biological testing can provide a closer
            look at the health of your lawn or garden. By
            quantifying the microorganisms in your soil, you can track the effectiveness of
            your soil management plan.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Laboratory glass in front with computer report in back"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/soilLab.webp`}
            title="Knowledge"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Full Biological Health Report
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            The biological test use ratios of specific bacteria and fungi to provide 15 detailed
            levels of microbes in your soil. By comparing these ratios
            to optimal levels, you can track how your soil&apos;s biological health compares to
            ideal growing conditions.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Test kit box moving quickly on back of truck"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/fastShipping.webp`}
            title="Shipping"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Express Shipping Included
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            For higher levels of accuracy, soil samples should be sent to the testing lab quickly
            in temperature controlled packaging. The cost of the test kit includes all these
            materials along with express shipping, without any additional charge to you.
          </Typography>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          A Soil Biology Report With Convenience and Ease
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Like other Rx Soil tests, your soil biology test is processed quickly, and you will
            receive your results usually within a week of sending in your sample. Your soil biology
            report will detail the following factors:
            <br />
            <br />
            <ul className={classes.bioTestPropertiesList}>
              <li>Total Detectable Biomass</li>
              <li>Bacteria Amounts and Classifications</li>
              <li>Fungi Amounts and Classifications</li>
              <li>Protozoa Amounts</li>
              <li>Functional Group Diversity</li>
              <li>Fungus to Bacterial Ratio</li>
              <li>Protozoa to Bacterial Ratio</li>
              <li>Gram(+) to Gram(-) Bacteria Ratio</li>
              <li>Weight and Percentage Breakdown of Biomass</li>
              <li>Saturated and Unsaturated Fatty Acid Ratio</li>
              <li>Monosaturated to Polysaturated Fat Ratio</li>
            </ul>
            <br />
            All of your information can be assigned to your customized growing areas, allowing you
            to compare the biological effectiveness of your soil management plan across locations
            and years at your property. An partial example of the data presented in your report:
          </Typography>
        </div>
        <br />
        <br />
        <img
          alt="Biological data breakdown by ratio as a bar chart"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/bioSample2.webp`}
          loading="lazy"
        />
        <img
          alt="Biological data breakdown by weight as a pie chart"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/bioSample1.webp`}
          loading="lazy"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started? Purchase our microbial soil analysis now or contact us with
          any questions.
        </Typography>
      </div>
      <br />
      <br />
      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Rx Soil Biological Test
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $189.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuy('Bottom')}
                >
                  BUY NOW
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="275"
              width="275"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/cubeBox.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default PLFA;
