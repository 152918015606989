// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
// MUI Icons and Font Awesome
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/B2CBanner.webp)`,
    backgroundPositionY: '100%',
    backgroundPositionX: '100%',
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0,
  },
  heroBox: {
    display: 'table',
    position: 'relative',
    textAlign: 'left',
    height: '100%',
    margin: 'auto 40px',
    maxWidth: '70%',
    zIndex: 1,
    [theme.breakpoints.down(900)]: {
      maxWidth: '100%',
      '& h1': {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down(500)]: {
      margin: 'auto 20px',
    },
  },
  textHeader: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    color: '#fff',
    fontWeight: 600,
    fontSize: 48,
    [theme.breakpoints.down(500)]: {
      fontSize: 28,
    },
  },
  ctaForm: {
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      minWidth: 582,
    },
  },
  cta2Form: {
    padding: 16,
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      padding: 0,
      minWidth: 582,
    },
  },
  videoSection: {
    marginTop: 50,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
  secondCta: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  sectionIcon: {
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      margin: '0 auto',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionIconMob: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      display: 'block',
      width: '100%',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: 200,
    },
  },
  sectionIconDesk: {
    display: 'block',
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionText: {
    margin: '0 20px',
    width: '70%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  boxImg: {
    height: 'auto',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
    [theme.breakpoints.up(501)]: {
      width: 420,
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    marginTop: 70,
    marginBottom: 40,
    padding: 80,
    '& .MuiGrid-root.MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    [theme.breakpoints.down(510)]: {
      padding: 10,
      '& h4': {
        marginTop: 40,
        marginBottom: 40,
        fontSize: 20,
      },
    },
  },
  analysis: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  bottomCTA: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  mainSection: {
    height: 440,
    width: 'auto',
    margin: 40,
    padding: 20,
    [theme.breakpoints.down(900)]: {
      height: 'auto',
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  recommendationsImg: {
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bagImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 400,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 360,
      width: '100%',
      height: 'auto',
    },
  },
  shipImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 340,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 480,
      width: '100%',
      height: 'auto',
    },
  },
  fabContainer: {
    zIndex: 1000,
    width: 350,
    height: 500,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Canna = () => {
  const classes = useStyles();
  const [messageTwo] = useState('');
  const [messageOnTwo] = useState(false);
  const [severityTwo] = useState('error');
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Home',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/`,
    page_path: '/',
  });

  const handleBuy = (which) => {
    gtag('event', 'add_to_cart', {
      event_category: `Cannaversion - Buy ${which} Button Clicked`,
      event_label: `Cannaversion - Buy ${which} Button Clicked`,
      value: `Cannaversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'conversion', {
      send_to: 'AW-831484399/JYzACJ3Wu9MCEO_jvYwD',
    });
    window.location.replace('https://app.rxsoil.com/checkout/236-1');
  };

  return (
    <>
      <CssBaseline />
      <Header current="home" />
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Box className={classes.heroBox}>
          <Typography className={classes.textHeader} component="h1" variant="h1" align="center">
            The only soil test kit with recommendations specifically for your &ldquo;special&rdquo;
            garden.
          </Typography>
        </Box>
      </div>
      {/* End hero unit */}

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Flowers in laboratory glass"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/science.webp`}
            title="Fast"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Increase Yield and Quality
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Test for 13 different soil properties with clear, accurate results. With Rx Soil,
            you&apos;ll know exactly what your plant and soil needs, when to put it down, and
            how much to place.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Money returning to yourself"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/save.webp`}
            title="Save"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Fast Free Shipping
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Rx Soil ships soil analysis kits nationwide that you send directly to the lab, giving
            you test results and soil balancing instructions right from home in about a week.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Planet earth"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/earth.webp`}
            title="Environment"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Responsible Soil Management
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            By tailoring specific recommendations to your soil, with our advanced AI you can achieve
            balance without overusing fertilizers. Rx Soil can help track your soil health and
            product applications year over year.
          </Typography>
        </div>
      </Container>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Rx Soil Analysis Kit
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $39.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuy('Top')}
                >
                  BUY NOW
                </Button>
                <Fade
                  severity={severityTwo}
                  in={messageOnTwo}
                >
                  <Alert>
                    {messageTwo}
                  </Alert>
                </Fade>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img alt="Soil analysis kit" className={classes.boxImg} src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`} />
          </div>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          What&apos;s inside a Rx Soil Analysis Kit?
        </Typography>
        <br />
        <br />
        <Typography variant="h5" align="center" paragraph>
          Easy to understand instruction card:
        </Typography>
        <img alt="Four step instruction card for sampling soil and sending it to the lab" className={classes.instructionsImg} src={`${process.env.PUBLIC_URL}/images/4x6instructionsFront_B2C.webp`} />
        <br />
        <div className={classes.twoSections}>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Compostable soil collection bag:
            </Typography>
            <img alt="Soil collection bag" className={classes.bagImg} src={`${process.env.PUBLIC_URL}/images/bag.webp`} />
          </div>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Pre-paid shipping label to our lab:
            </Typography>
            <img alt="Soil analysis kit with prepaid shipping label" className={classes.shipImg} src={`${process.env.PUBLIC_URL}/images/b2cBoxBack.webp`} />
          </div>
        </div>
      </div>

      <div className={classes.analysis}>
        <Typography variant="h4" align="center" gutterBottom>
          Simple to Understand Results
        </Typography>
        <br />
        <br />
        <img alt="Soil analysis graph and chart showing optimal ranges" className={classes.instructionsImg} src={`${process.env.PUBLIC_URL}/images/soil-analysis.webp`} />
        <br />
      </div>

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          Advanced In-Depth Recommendations
        </Typography>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIcon}>
              <img alt="Grid with plot marks" src={`${process.env.PUBLIC_URL}/images/growingArea.webp`} />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                First we gather some information about your growing area like square footage and
                location.
              </Typography>
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIconMob}>
              <img alt="Plant labeled with growth markers" src={`${process.env.PUBLIC_URL}/images/plant.webp`} />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                Then you select the type of plants that you&apos;re growing and your preferred
                products.
              </Typography>
            </div>
            <div className={classes.sectionIconDesk}>
              <img alt="Plant labeled with growth markers" src={`${process.env.PUBLIC_URL}/images/plant.webp`} />
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIcon}>
              <img alt="Computer screen with soil analysis readout" src={`${process.env.PUBLIC_URL}/images/soilLab.webp`} />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                The laboratory analysis provide precise major and minor element levels, the
                capacity to retain nutrients, ph level, and more.
              </Typography>
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIconMob}>
              <img alt="Multi level grid with plot marks" src={`${process.env.PUBLIC_URL}/images/soilData.webp`} />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                Finally we provide you detailed recommendations for which products to put down,
                when, and how much.
              </Typography>
            </div>
            <div className={classes.sectionIconDesk}>
              <img alt="Multi level grid with plot marks" src={`${process.env.PUBLIC_URL}/images/soilData.webp`} />
            </div>
          </div>
        </Container>
        <img alt="Soil amendment recommendation chart showing instructions" className={classes.recommendationsImg} src={`${process.env.PUBLIC_URL}/images/recommendations.webp`} />
      </div>

      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started? Purchase a soil test kit today or contact us with any questions.
        </Typography>
      </div>
      <br />
      <br />
      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Rx Soil Analysis Kit
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $39.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuy('Bottom')}
                >
                  BUY NOW
                </Button>
                <Fade
                  severity={severityTwo}
                  in={messageOnTwo}
                >
                  <Alert>
                    {messageTwo}
                  </Alert>
                </Fade>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img alt="Soil analysis kit" className={classes.boxImg} src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`} />
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Canna;
