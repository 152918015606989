// React Redux Core
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { render } from 'react-snapshot';
// Material-UI and other Libraries
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// Adapify
import theme from './theme';
import App from './App';

const rootElement = document.getElementById('root');

render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  rootElement,
);
