// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/gardenMetal.webp)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'right',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  ctaWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 70,
    marginBottom: 70,
    [theme.breakpoints.down(1107)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  primaryCta: {
    [theme.breakpoints.up(870)]: {
      margin: 10,
    },
    margin: '20px auto',
    maxWidth: 600,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bioTestPropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
}));

const Metals = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Metals Testing',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/metals`,
    page_path: '/metals',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  const handleBuyLead = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 49.95,
      items: [{
        id: 'RX003',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/239-1?source=${window.localStorage.getItem('source')}`);
  };

  const handleBuyMetals = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 199.95,
      items: [{
        id: 'RX004',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/240-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="metals" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Test for Potentially Toxic Metals in Your Soil
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              RxSoil simplifies the process of testing for lead and other known toxic metals in
              your soil. Discover the amount of environmentally available metals present in
              your soil, and use this along with environmental resources to make informed
              decisions about your property, yard, or growing area.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Laboratory with scientists"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/labIcon.webp`}
            title="Microbiome"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Accredited Laboratory Analysis
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Whether you&apos;re testing for lead by itself, or a broader metals panel,
            using an EPA accredited lab to handle and test your soil sample can give
            added confidence in the precision and accuracy in your metal analysis.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="System with gears and computer"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/systemIcon.webp`}
            title="Knowledge"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Resources and Insight
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            RxSoil provides you with EPA resources and guidelines to best interpret your results.
            By knowing your levels and the testing methods that were used, you can see a more
            complete picture of your soil health.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Test kit box moving quickly on back of truck"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/fastShipping.webp`}
            title="Shipping"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Fast Free Shipping
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Two-way shipping costs are included - to get the kit to you and to mail your
            soil sample to the lab. Get your heavy metals report right from the comfort of
            your home in about two weeks.
          </Typography>
        </div>
      </Container>

      <Container className={classes.ctaWrapper} maxWidth="lg">
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Lead-Only Test Kit
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $49.95
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyLead('Top')}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Soil analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Full RCRA 8 Test Kit
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $199.95
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyMetals('Top')}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Soil analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          What Is the RCRA 8 List of Metals?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            The Environmental Protection Agency (EPA) estabilished a set of regulations as part of
            the Resource Conservation and Recovery Act (RCRA) that concerns the classification and
            monitoring of heavy metals in our households and waste. When looking at the soil around
            our living spaces, this list of heavy metals, referred to as the RCRA 8, provides a
            framework for the types of heavy metals to test for that can be potentially harmful.

            RxSoil offers a package test for the RCRA 8 metals, including:
            <br />
            <br />
            <ul className={classes.bioTestPropertiesList}>
              <li>Arsenic</li>
              <li>Barium</li>
              <li>Cadmium</li>
              <li>Chromium</li>
              <li>Lead</li>
              <li>Mercury</li>
              <li>Selenium</li>
              <li>Silver</li>
            </ul>
            <br />
            A partial example of the data presented in your report:
          </Typography>
        </div>
        <br />
        <br />
        <img
          alt="Metals sample data showing lead and mercury"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/metalReport.webp`}
          loading="lazy"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started? Purchase our lead or full RCRA 8 test kit now, or contact us with
          any questions.
        </Typography>
      </div>
      <br />
      <br />
      <Container className={classes.ctaWrapper} maxWidth="lg">
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Lead-Only Test Kit
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $49.95
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyLead('Bottom')}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Soil analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Full RCRA 8 Test Kit
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $199.95
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyMetals('Bottom')}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Soil analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Metals;
