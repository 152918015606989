// React Redux Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '10px 40px',
  },
  link: {
    color: theme.palette.primary.main,
    marginTop: 30,
    '&:hover': {
      cursor: 'pointer',
      color: '#43a047',
    },
    '&:focus': {
      outline: 'none',
      color: '#43a047',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.primary.gray,
  },
  okay: {
    float: 'right',
    marginButton: 5,
  },
}));

const TermsOfServiceDialog = (linkText) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className={classes.link}
        tabIndex="0"
        onClick={handleClickOpen}
        onKeyDown={(event) => {
          // For keyboard enter events
          if (event.keyCode === 13 || event.keyCode === 32) {
            handleClickOpen();
          }
        }}
        role="button"
      >
        {linkText}
      </span>
      <Dialog open={open} onClose={handleClose} scroll="paper" hideBackdrop>
        <DialogTitle>
          Terms and Conditions
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" color="primary" paragraph>
            SERVICES AND SUPPORT
          </Typography>
          <Typography paragraph>
            Subject to the terms of this Agreement, Company will use commercially reasonable
            efforts to provide Customer the Services detailed in purchase order. As part of
            the registration process, Customer will identify a user name and
            password for Customer’s Company account. Company reserves the right to refuse
            registration of, or cancel passwords it deems inappropriate.
          </Typography>
          <Typography paragraph>
            Subject to the terms hereof, Company will provide Customer with reasonable technical
            support services.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            RESTRICTIONS AND RESPONSIBILITIES
          </Typography>
          <Typography paragraph>
            Customer will not, directly or indirectly: reverse engineer, decompile, disassemble
            or otherwise attempt to discover the source code, object code or underlying
            structure, ideas, know-how or algorithms relevant to the Services or any software,
            documentation or data related to the Services (“Software”); modify, translate, or
            create derivative works based on the Services or any Software (except to the extent
            expressly permitted by Company or authorized within the Services); use the Services
            or any Software for timesharing or service bureau purposes or otherwise for the
            benefit of a third; or remove any proprietary notices or labels.  With respect to
            any Software that is distributed or provided to Customer for use on Customer premises
            or devices, Company hereby grants Customer a non-exclusive, non-transferable,
            non-sublicensable license to use such Software during the Term only in connection
            with the Services.
          </Typography>
          <Typography paragraph>
            Further, Customer may not remove or export from the United States or allow the export
            or re-export of the Services, Software or anything related thereto, or any direct
            product thereof in violation of any restrictions, laws or regulations of the United
            States Department of Commerce, the United States Department of Treasury Office of
            Foreign Assets Control, or any other United States or foreign agency or authority.
            <br />
            As defined in FAR section 2.101, the Software and documentation are “commercial items”
            and according to DFAR section 252.227 7014(a)(1) and (5) are deemed to be “commercial
            computer software” and “commercial computer software documentation.”  Consistent with
            DFAR section 227.7202 and FAR section 12.212, any use modification, reproduction,
            release, performance, display, or disclosure of such commercial software or commercial
            software documentation by the U.S. Government will be governed solely by the terms of
            this Agreement and will be prohibited except to the extent expressly permitted by the
            terms of this Agreement.
          </Typography>
          <Typography paragraph>
            Customer represents, covenants, and warrants that Customer will use the Services only in
            compliance with Company’s standard published policies then in effect (the “Policy”) and
            all applicable laws and regulations.  Customer hereby agrees to indemnify and hold
            harmless Company against any damages, losses, liabilities, settlements and expenses
            (including without limitation costs and attorneys’ fees) in connection with any claim or
            action that arises from an alleged violation of the foregoing or otherwise from
            Customer’s use of Services. Although Company has no obligation to monitor Customer’s use
            of the Services, Company may do so and may prohibit any use of the Services it believes
            may be (or alleged to be) in violation of the foregoing.
          </Typography>
          <Typography paragraph>
            Customer shall be responsible for obtaining and maintaining any equipment and ancillary
            services needed to connect to, access or otherwise use the Services, including, without
            limitation, modems, hardware, servers, software, operating systems, networking
            and the like (collectively, “Equipment”).  Customer shall also be responsible
            for maintaining the security of the Equipment, Customer account, passwords (including
            but not limited to administrative and user passwords) and files, and for all uses of
            Customer account or the Equipment with or without Customer’s knowledge or consent.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            CONFIDENTIALITY & PROPRIETARY RIGHTS
          </Typography>
          <Typography paragraph>
            Each party (the “Receiving Party”) understands that the other party (the “Disclosing
            Party”) has disclosed or may disclose business, technical or financial information
            relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary
            Information” of the Disclosing Party).  Proprietary Information of Company includes non-
            public information regarding features, functionality and performance of the Service.
            Proprietary Information of Customer includes non-public data provided by Customer to
            Company to enable the provision of the Services (“Customer Data”). The Receiving Party
            agrees: (i) to take reasonable precautions to protect such Proprietary Information, and
            (ii) not to use (except in performance of the Services or as otherwise permitted herein)
            or divulge to any third person any such Proprietary Information.  The Disclosing Party
            agrees that the foregoing shall not apply with respect to any information after five (5)
            years following the disclosure thereof or any information that the Receiving Party can
            document (a) is or becomes generally available to the public, or (b) was in its
            possession or known by it prior to receipt from the Disclosing Party, or (c) was
            rightfully disclosed to it without restriction by a third party, or (d) was
            independently developed without use of any Proprietary Information of the Disclosing
            Party or (e) is required to be disclosed by law.
          </Typography>
          <Typography paragraph>
            Customer shall own all right, title and interest in and to the Customer Data, as well as
            any data that is based on or derived from the Customer Data and provided to Customer as
            part of the Services.  Company shall own and retain all right, title and interest in and
            to (a) the Services and Software, all improvements, enhancements or modifications
            thereto, (b) any software, applications, inventions or other technology developed in
            connection with Implementation Services or support, and (c) all intellectual property
            rights related to any of the foregoing.
          </Typography>
          <Typography paragraph>
            Notwithstanding anything to the contrary, Company shall have the right collect and
            analyze data and other information relating to the provision, use and performance of
            various aspects of the Services and related systems and technologies (including, without
            limitation, information concerning Customer Data and data derived therefrom), and
            Company will be free (during and after the term hereof) to (i) use such information and
            data to improve and enhance the Services and for other development, diagnostic and
            corrective purposes in connection with the Services and other Company offerings, and
            (ii) disclose such data solely in aggregate or other de-identified form in connection
            with its business. No rights or licenses are granted except as expressly set forth
            herein.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            WARRANTY AND DISCLAIMER
          </Typography>
          <Typography paragraph>
            Company shall use reasonable efforts consistent with prevailing industry standards to
            maintain the Services in a manner which minimizes errors and interruptions in the
            Services. Services may be temporarily unavailable for scheduled maintenance or for
            unscheduled emergency maintenance, either by Company or by third-party providers, or
            because of other causes beyond Company’s reasonable control, but Company shall use
            reasonable efforts to provide advance notice in writing or by e-mail of any extended
            service disruption.  HOWEVER, COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE
            UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
            OBTAINED FROM USE OF THE SERVICES.  EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE
            SERVICES ARE PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE CUSTOMER ACKNOWLEDGES AND AGREES THAT:
            (1) THE CUSTOMER USES THE SERVICES AT THEIR SOLE RISK. (2) THE COMPANY MAKES NO
            WARRANTY THAT (a) THE SERVICES WILL MEET YOUR REQUIREMENTS; (b) THE QUALITY OF
            ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
            THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS; (c) THE SERVICES OR INFORMATION
            OBTAINED SHOULD BE USED AS PART OF A DETERMINATION REGARDING HEALTH OR SAFETY OF THE
            CUSTOMER’S PROPERTY AND (d) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED. (3) NO ADVICE
            OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE COMPANY OR THROUGH OR
            FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS AND
            CONDITIONS.
          </Typography>
          <Typography paragraph>
            Customer
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            INDEMNITY
          </Typography>
          <Typography paragraph>
            Company shall hold Customer harmless from liability to third parties resulting from
            infringement by the Service of any United States patent or any copyright or
            misappropriation of any trade secret, provided Company is promptly notified of any and
            all threats, claims and proceedings related thereto and given reasonable assistance and
            the opportunity to assume sole control over defense and settlement; Company will not be
            responsible for any settlement it does not approve in writing.  The foregoing
            obligations do not apply with respect to portions or components of the Service (i) not
            supplied by Company, (ii) made in whole or in part in accordance with Customer
            specifications, (iii) that are modified after delivery by Company, (iv) combined with
            other products, processes or materials where the alleged infringement relates to such
            combination, (v) where Customer continues allegedly infringing activity after being
            notified thereof or after being informed of modifications that would have avoided the
            alleged infringement, or (vi) where Customer’s use of the Service is not strictly in
            accordance with this Agreement.  If, due to a claim of infringement, the Services are
            held by a court of competent jurisdiction to be or are believed by Company to be
            infringing, Company may, at its option and expense (a) replace or modify the Service to
            be non-infringing provided that such modification or replacement contains substantially
            similar features and functionality, (b) obtain for Customer a license to continue using
            the Service, or (c) if neither of the foregoing is commercially practicable, terminate
            this Agreement and Customer’s rights hereunder and provide Customer a refund of any
            prepaid, unused fees for the Service.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            LIMITATION OF LIABILITY
          </Typography>
          <Typography paragraph>
            WITHIN THE LIMITS ALLOWED BY LAW, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE COMPANY
            SHALL NOT BE LIABLE FOR DAMAGES RESULTING FROM: (a) THE USE OR THE INABILITY TO USE THE
            SERVICES; (b) ANY ACTION YOU TAKE BASED ON THE INFORMATION YOU RECEIVE IN, THROUGH OR
            FROM THE SERVICES, (c) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE
            AND CONFIDENTIAL, (d) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
            FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR TRANSACTIONS
            ENTERED INTO THROUGH OR FROM THE SERVICES; (e) UNAUTHORIZED ACCESS TO YOUR TRANSMISSIONS
            OR DATA; (f) THE IMPROPER AUTHORIZATION FOR THE SERVICES BY SOMEONE CLAIMING SUCH
            AUTHORITY; or (g) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            MISCELLANEOUS
          </Typography>
          <Typography paragraph>
            If any provision of this Agreement is found to be unenforceable or invalid, that
            provision will be limited or eliminated to the minimum extent necessary so that this
            Agreement will otherwise remain in full force and effect and enforceable.  This
            Agreement is not assignable, transferable or sublicensable by Customer except with
            Company’s prior written consent.  Company may transfer and assign any of its rights and
            obligations under this Agreement without consent.  This Agreement is the complete and
            exclusive statement of the mutual understanding of the parties and supersedes and
            cancels all previous written and oral agreements, communications and other
            understandings relating to the subject matter of this Agreement, and that all waivers
            and modifications must be in a writing signed by both parties, except as otherwise
            provided herein.  No agency, partnership, joint venture, or employment is created as a
            result of this Agreement and Customer does not have any authority of any kind to bind
            Company in any respect whatsoever.  In any action or proceeding to enforce rights under
            this Agreement, the prevailing party will be entitled to recover costs and attorneys’
            fees.  All notices under this Agreement will be in writing and will be deemed to have
            been duly given when received, if personally delivered; when receipt is electronically
            confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for
            next day delivery by recognized overnight delivery service; and upon receipt, if sent by
            certified or registered mail, return receipt requested.  This Agreement shall be
            governed by the laws of the State of Delaware without regard to its conflict of laws
            provisions.
          </Typography>
          <Button onClick={handleClose} color="primary" variant="contained" size="medium" className={classes.okay}>
            Okay
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TermsOfServiceDialog;
